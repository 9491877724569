/* General */

html {
  scroll-behavior: smooth;
}

body {
  line-height: 1;
  overflow: overlay;
}

a {
  text-decoration: none;
  font-weight: inherit;
  color: inherit;
}

ul {
  margin: 0;
}

/* Scrollbar */

/* ::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid #00000000;
  border-radius: 8px;
  background: #ccc;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:active {
  background: #aaa;
  background-clip: padding-box;
} */

/* Scroll To Top */

.scroll-to-top {
  z-index: 1000;
  position: fixed;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  padding-top: 7px;
  border-radius: 100%;
  text-align: center;
  font-size: 18px;
  color: transparent;
  background-color: transparent;
  transition: 0.2s;
}

.scroll-to-top.show {
  color: #fff;
  background-color: #323232;
  cursor: pointer;
}
.scroll-to-top.show:hover {
  bottom: 16px;
  color: #fff;
  background-color: #ff4830;
}
.scroll-to-top.hide {
  user-select: none;
  pointer-events: none;
}

/* Toastify */

.Toastify__toast--info {
  background: #2080ff !important;
}
.Toastify__toast--error {
  background: #ff2050 !important;
}
.Toastify__toast--warning {
  background: #ffa040 !important;
}
.Toastify__toast--success {
  background: #20c820 !important;
}

/* Material UI Jank */

.MuiTimelineItem-missingOppositeContent:before {
  flex: 0 !important;
  content: none !important;
  padding: 0 !important;
}

/* Material UI Overrides */

.MuiList-padding {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.MuiListItem-gutters {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.MuiMenuItem-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 14px !important;
}

.MuiTooltip-tooltip {
  max-width: 1280px !important;
}

/* Image Gallery Fixes */

.image-gallery-svg {
  max-width: 40px !important;
  max-height: 80px !important;
  min-width: 24px !important;
  min-height: 24px !important;
}
.image-gallery-image, .image-gallery-thumbnail-image, .ril-image-current {
  background: #000000;
}

/* Hourglass Animation */

@keyframes hourglass {
  0% {
    transform: rotateZ(0deg);
  }
  40% {
    transform: rotateZ(180deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  90% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes hourglass2 {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  45% {
    transform: translate(-50%, -50%) rotateZ(180deg);
  }
  50% {
    transform: translate(-50%, -50%) rotateZ(180deg);
  }
  95% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

/* Pulse Animation */

@keyframes pulse {
  0% {
    filter: brightness(80%);
  }
  45% {
    filter: brightness(100%);
  }
  80% {
    box-shadow: 0 0 0 8px #00000000;
  }
  100% {
    filter: brightness(80%);
    box-shadow: 0 0 0 0 #00000000;
  }
}

/* Selected Animation */

@keyframes selected {
  0% {
    background: #32364020;
  }
  50% {
    background: #32364040;
  }
  100% {
    background: #32364020;
  }
}
